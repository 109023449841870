/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
// Define variables for common breakpoints
$small-screen: 576px;
$medium-screen: 768px;
$large-screen: 992px;
$extra-large-screen: 1200px;


@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@font-face {
    font-family: 'Gotham Book';
    src: url(./assets/fonts/Gotham/GothamBook.woff) format('truetype');
}
@font-face {
    font-family: 'Gotham Bold';
    src: url(./assets/fonts/Gotham/Gotham-Bold.woff) format('truetype');
}

body {
    // background: #F7F7F7;
    --ion-default-font: "Gotham", sans-serif;
}

.ion-flex {
    display: flex;
}

.mt-30 {
    margin-top: 3.75vh;
}

.mb-30 {
    margin-bottom: 3.75vh;
}

.mr-30 {
    margin-right: 3.75vh;
}

.mr-30 {
    margin-left: 3.75vh;
}

.ion-color-red {
    color: var(--ion-color-danger)
}

.ion-color-green {
    color: var(--ion-color-success)
}

.ion-color-black {
    color: var(--ion-color-black)
}

.ion-color-orange {
    color: var(--ion-color-orange)
}
.content-box {
    --padding-start: 50px;
    --padding-top: 5px;
    --padding-end: 50px;
    --background: #c7c7c7;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    font-family: 'Gotham';
}
@media (max-width: $medium-screen) {
    // Styles for screens wider than or equal to $medium-screen
    .content-box {
        --padding-start: 10px;
        --padding-top: 5px;
        --padding-end: 10px;
        --background: #f7f7f7;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        font-family: 'Gotham';
    } 
  }
.main-card {
    border-radius: 8px;
    height:80vh
}
.ion-center {
    display: flex;
    align-items: center;
    justify-content:center;
}
ion-text {
    font-family: 'Gotham';
}

.driver-bal {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 50px;
    .bal {
        font-size:30px;
        padding-left: 10px;
    }
}
.cancel-box {
    ion-icon {
        font-size:35px
    }
    ion-text {
        font-size:14px;
        font-weight: 600;
    }

}